(function ($) {
    let $fn = $(".comp_room_detail");

    if ($fn.length) {
        let $gallery_slider = $fn.find("[data-slider]"),
            $gallery_counter = $fn.find("[data-slider-counter]"),
            $rooms_slider = $fn.find("[data-slider-rooms]");

        if($gallery_slider.children().length > 1) {
            $gallery_slider.slick({
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: true,
                dots: false,
                prevArrow: "<i class=\"icon icon--arrow-big-left slick-prev\"></i>",
                nextArrow: "<i class=\"icon icon--arrow-big-right slick-next\"></i>",
                responsive: [
                    {
                        breakpoint: 960,
                        settings: {
                            prevArrow: "<i class=\"icon icon--arrow-left slick-prev\"></i>",
                            nextArrow: "<i class=\"icon icon--arrow-right slick-next\"></i>",
                        }
                    }
                ]
            });
            $gallery_slider.on('afterChange', function(event, slick, currentSlide){
                $gallery_counter.text(currentSlide+1);
            });
        }

        $gallery_slider.lightGallery({
            thumbnail: true,
            exThumbImage: 'data-exthumbimage',
            selector: ".elm_item",
            fullScreen: false,
            zoom: true,
            actualSize: false,
            hash: true,
            download: true,
            autoplay: false,
            autoplayControls: true
        });

        if($rooms_slider.children().length > 1) {
            $rooms_slider.slick({
                centerMode: true,
                centerPadding: "0px",
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: true,
                dots: false,
                prevArrow: "<i class=\"icon icon--arrow-left slick-prev\"></i>",
                nextArrow: "<i class=\"icon icon--arrow-right slick-next\"></i>",
                responsive: [
                    {
                        breakpoint: 768,
                        settings: {
                            centerMode: false
                        }
                    }
                ]
            })
        }
    }
})(jQuery);